import { render, staticRenderFns } from "./App.vue?vue&type=template&id=23093065"
var script = {}
import style0 from "./App.vue?vue&type=style&index=0&id=23093065&prod&lang=scss"


/* normalize component */
import normalizer from "!../node_modules/.pnpm/vue-loader@15.11.1_@vue+compiler-sfc@3.5.13_css-loader@6.11.0_webpack@5.98.0__lodash@4._babb32925cd4b8a8c1892c895ee75fdf/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports